import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1
      })),
      state('closed', style({
        paddingTop: 0,
        paddingBottom: 0,
        height: 0,
        opacity: 0
      })),
      transition('open => closed', [animate('.15s ease-in')]),
      transition('closed => open', [animate('.2s ease-out')])
    ])
  ]
})
export class PermissionComponent implements OnInit, OnChanges {

  @Input() public open = false;
  @Input() public disable = false;
  @Input() public color: string;
  @Input() public control: UntypedFormGroup;
  @Input() public title: string;
  @Input() public subtitle: string;

  @Output('onChange') public onChange = new EventEmitter<UntypedFormGroup>();

  public fields: string[] = [];
  public toggleControl = new UntypedFormControl(this.open);

  public constructor() {
  }

  public ngOnInit(): void {
    if (this.control.controls) {
      this.fields = Object.keys(this.control.controls);
    }
    if (!this.open) {
      this.control.disable();
    }
    this.toggleControl.valueChanges.subscribe((res): void => res ? this.control.enable() : this.control.disable());
  }

  public ngOnChanges({
    disable, open
  }: SimpleChanges): void {
    (disable && disable.currentValue) || this.disable ? this.toggleControl.disable() : this.toggleControl.enable();
    (open && open.currentValue !== open.previousValue) && this.toggleControl.setValue(open.currentValue);
  }
}
