import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import * as userActions from '../../reducers/user/user.actions';
import { AccountService, CompanyDto } from '../../services/account/account.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveAccountGuard  {

  public constructor(
    public authService: AuthService,
    private accountService: AccountService,
    private store: Store<AppState>,
  ) {
  }

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.authService.isUserAuthenticated() && !environment.useMockedServices) {
      return false;
    }

    return this.store.select('user', 'currentUser')
      .pipe(
        take(1),
        switchMap((res): Observable<CompanyDto> => {
          if (res) {
            return of(res);
          }
          return this.accountService.getRegistrationCompany()
            .pipe(
              tap((res: CompanyDto): void => {
                this.store.dispatch(new userActions.SetCurrentUser(res));
              }),
            );
        }),
        map((res: CompanyDto): boolean => {
          if (res && (res.selfregisrationStatus === 'ACTIVE' || !res.selfregisrationStatus)) {
            return false;
          }
          return true;
        }),
        catchError((): Observable<boolean> => {
          return of(false);
        })
      );
  }
}

