<div class="mb-15px">
  <h3 class="mb-15px">{{title | translate}}</h3>

  <div
    [class.enabled]="!deactivateSwitchTemplate || (toggle.value && deactivateSwitchTemplate)"
    class="replace-content"
  >
    <ng-container [ngTemplateOutlet]="replaceContentTemplate"></ng-container>
  </div>

  <mat-slide-toggle
    *ngIf="deactivateSwitchTemplate"
    [class.spacing]="!toggle.value"
    [formControl]="toggle"
  >
    <ng-container [ngTemplateOutlet]="deactivateSwitchTemplate"></ng-container>
  </mat-slide-toggle>

  <div
    [class.content-toggled]="deactivateSwitchTemplate"
    [class.enabled]="!!deactivateSwitchTemplate && !toggle.value || !deactivateSwitchTemplate"
    class="content"
  >
    <ng-content></ng-content>
  </div>
</div>