<div class="registration-container">

  <rs-top-bar-menu
    *ngIf="!fullscreen"
    [cdnUrl]="topBarCdn"
    [environment]="topBarEnvironment"
    [footer]="false"
    application="Registration"
  >
  </rs-top-bar-menu>

  <rs-sub-header
    *ngIf="!fullscreen"
    [menuItems]="subheaderMenu"
    sticky
  >
    {{ headerTitle | translate }}
  </rs-sub-header>

  <div [class.container]="!fullscreen">
    <router-outlet></router-outlet>
  </div>

  <rs-footer
    [cookiesKey]="'FOOTER.COOKIES'"
    [disclaimerKey]="'FOOTER.DISCLAIMER'"
    [needHelpKey]="'FOOTER.NEED_HELP'"
  >
  </rs-footer>

</div>