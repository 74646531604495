<div class="image-container">
  <img src="../../../../assets/img/car.png" />
</div>

<div class="container">
  <h1 class="text-center">{{'THANK_YOU' | translate}}</h1>
  <p innerHTML="{{'SUCCESS_DESCRIPTION' | translate}}"></p>
  <button
    (click)="redirect()"
    class="rs-btn--primary"
    mat-button
  >{{"CREATE_USER" | translate}}</button>
</div>