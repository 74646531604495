import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PhaseOneComponent } from '../pages/phase-one/phase-one.component';
import { CheckOutComponent } from '../pages/check-out/check-out.component';
import { PhaseTwoComponent } from '../pages/phase-two/phase-two.component';
import { SimulatorComponent } from '../pages/phase-two/simulator/simulator.component';
import { SetupTwoComponent } from '../pages/phase-two/setup/setup-two.component';
import { SetupComponent } from '../pages/phase-one/setup/setup.component';
import { SuccessComponent } from '../pages/phase-two/success/success.component';
import { ActiveAccountGuard } from '../guards/active-account/active-account.guard';

const routes: Routes = [
  {
    path: 'public',
    component: PhaseOneComponent,
    data: {
      title: 'Renta MGT - Registration',
      auth: false
    },
    children: [
      {
        path: '',
        redirectTo: 'information',
        pathMatch: 'full'
      },
      {
        path: 'information',
        component: SetupComponent,
      },
      {
        path: 'check-out',
        component: CheckOutComponent,
      }
    ]
  },
  {
    path: 'portal',
    component: PhaseTwoComponent,
    data: {
      title: 'Renta MGT - Registration II',
      auth: true
    },
    children: [
      {
        path: '',
        redirectTo: 'pricing',
        pathMatch: 'full'
      },
      {
        path: 'pricing',
        component: SimulatorComponent,
        data: {
          selected: 0
        },
        canActivate: [ActiveAccountGuard]
      },
      {
        path: 'platforms',
        component: SetupTwoComponent,
        data: {
          selected: 1
        },
        canActivate: [ActiveAccountGuard]
      },
      {
        path: 'success',
        component: SuccessComponent,
        data: {
          fullscreen: true
        }
      }
    ]
  },
  {
    path: '',
    redirectTo: '/public/information',
    pathMatch: 'full'
  },
  { path: '**', redirectTo: '/public/information' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
