import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { NativeDateAdapter } from '@angular/material/core';

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

@Injectable({
  providedIn: 'root',
})
export class AppDateAdapter extends NativeDateAdapter {

  @Inject(LOCALE_ID) public override locale: string;

  public constructor(
    @Inject(LOCALE_ID) locale: string,
    private translateService: TranslateService
  ) {
    super();
    this.locale = locale;
  }

  public override format(date: Date, displayFormat: any): string {
    const formatString = (displayFormat === 'input') ? moment(date).format('L') : 'MMM YYYY';

    if (this.translateService) {
      moment.locale(this.translateService.currentLang);
    }
    return moment(date).format(formatString);
  }
}
