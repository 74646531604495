import { Component, Input } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-rs-selectable-card-extra',
  templateUrl: './rs-selectable-card-extra.component.html',
  styleUrls: ['./rs-selectable-card-extra.component.scss']
})
export class RsSelectableCardExtraComponent {

  @Input() public label: string;
  @Input() public name: string;
  @Input() public type: string;
  @Input() public validators: { [keys: string]: string };

  constructor() {
  }

  getValidators(): ValidatorFn[] {
    if (!this.validators.length) {
      return;
    }

    return Object.keys(this.validators).map(key => {
      const validator = Validators[key];
      if (typeof this.validators[key] == 'boolean') {
        return validator;
      }
      return validator(this.validators[key]);
    })
  }

}
