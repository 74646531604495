import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

export function ApiValidator(service: (...args) => Observable<any>): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return timer(1000).pipe(
      switchMap(() => service(control.value).pipe(
        map((res) => res ? { api: { value: control.value, valid: false, data: res } } : null),
        catchError(() => {
          return of({ server: { value: control.value, valid: false, data: false } })
        })
      ))
    )
  };
}
