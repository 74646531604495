import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getAPI, postAPI, reCaptchaAction } from '../services.utils';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public constructor(private http: HttpClient) {
  }

  public getPublicSupportedCountries(): Observable<any> {
    return reCaptchaAction('phaseOne_getSupportedCountries', (token) => {
      return getAPI(this.http, 'public/config/country', { reCAPTCHA: token });
    });
  }

  public getPublicSupportedGenders(): Observable<any> {
    return reCaptchaAction('phaseOne_getSupportedGenders', (token) => {
      return getAPI(this.http, 'public/config/gender', { reCAPTCHA: token });
    });
  }

  public getPublicSupportedLanguages(): Observable<any> {
    return reCaptchaAction('phaseOne_getSupportedLanguages', (token) => {
      return getAPI(this.http, 'public/config/language', { reCAPTCHA: token });
    });
  }

  public getSupportedCountries(): Observable<any> {
    return getAPI(this.http, 'config/country');
  }

  public getSupportedGenders(): Observable<any> {
    return getAPI(this.http, 'config/gender');
  }

  public getSupportedLanguages(): Observable<any> {
    return getAPI(this.http, 'config/language');
  }

  public getBrands(): Observable<BrandDto[]> {
    return getAPI(this.http, 'config/brands').pipe(
      map((brands): BrandDto[] => {
        return brands.map((brand: BrandDto): BrandDto => ({ ...brand, name: brand.name.toLowerCase() }))
      })
    )
  }

  public simulatorCalculate(body: { [key: string]: { [key: string]: number } }): Observable<any> {
    return postAPI(this.http, 'rsi/api/calculate', body);
  }

}

interface BrandDto {
  id: string;
  name: string
}
