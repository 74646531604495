<div class="platform {{platform || ''}}">
  <div
    [formGroup]="formControls"
    class="main"
  >
    <div class="row align-items-end header">
      <div class="col-4">
        <h2 class="pricing-card-title">{{cardTitle | translate}}</h2>
        <p>{{subtitle | translate}}</p>
      </div>
      <div class="col-4 legend">
        <p>{{ todayDate | date: "yyyy" }}</p>
      </div>
      <div class="col-4 legend">
        <p class="p-0">{{ 'EXPECTED_YEARLY_COST' | translate }}</p>
      </div>
    </div>

    <div
      *ngFor="let input of inputs; let i = index;"
      class="flex-nowrap form-line-wrapper"
    >
      <div
        [class.darker]="i % 2 == 0"
        class="row align-items-center form-line"
      >
        <div class="form-label col-4 d-flex align-items-center">
          <p>{{input.label | translate}}</p>
          <button
            (click)="tooltipDialog(input.info)"
            *ngIf="input.info"
            mat-icon-button
          >
            <mat-icon>info_outline</mat-icon>
          </button>
        </div>
        <div class="col-4">
          <mat-form-field class="form-field">
            <input
              (click)="$event.target.select()"
              [formControlName]="input.name"
              [type]="input.type || 'number'"
              class="w-100"
              matInput
              min="0"
            />
          </mat-form-field>
        </div>

        <div class="col-4">
          <p
            [class.enabled]="true"
            class="text-end price"
          >{{ values[input.name] || '-,--' }} €</p>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="total-wrapper d-flex justify-content-end">
      <p class="text-end total">{{platformUppercase}} {{ 'TOTAL_YEARLY_COST' | translate }}</p>
      <p
        [class.enabled]="true"
        class="text-end price"
      >{{ values.cost || '-,--' }} €</p>
    </div>
  </div>
</div>