import { Action } from '@ngrx/store';
import { Documents } from './app.reducer';

export enum ActionsList {
  setDocuments = 'Get conditions pdf from BE', // eslint-disable-line no-unused-vars
  setCountries = 'Get supported countries',// eslint-disable-line no-unused-vars
  setLanguages = 'Get supported languages',// eslint-disable-line no-unused-vars
  setGenders = 'Get supported genders'// eslint-disable-line no-unused-vars
}

export class ActionDTO implements Action {
  public readonly type: ActionsList;
  public payload: any;
}

export class SetDocuments implements ActionDTO {
  public readonly type = ActionsList.setDocuments;

  public constructor(public payload: Documents) {
  }
}

export class SetCountries implements ActionDTO {
  public readonly type = ActionsList.setCountries;

  public constructor(public payload: string[]) {
  }
}

export class SetLanguages implements ActionDTO {
  public readonly type = ActionsList.setLanguages;

  public constructor(public payload: string[]) {
  }
}

export class SetGenders implements ActionDTO {
  public readonly type = ActionsList.setGenders;

  public constructor(public payload: string[]) {
  }
}
