import { Component, Inject, OnInit } from '@angular/core';
import { ConfigService } from '../../../services/config/config.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { AccountService } from '../../../services/account/account.service';

@Component({
  selector: 'app-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss']
})
export class SimulatorComponent implements OnInit {

  public fullTotal = '-,--';
  public total: any;

  public requestedPlatforms = {};

  private _simulatorStatus = {
    divc: {
      leaseCars: 0,
      privateCars: 0,
      users: 0
    },
    mrt: {
      connectedUsers: 0,
      orders: 0,
      users: 0
    },
    ord: {
      orders: 0,
      users: 0
    },
    rei: {
      users: 0
    }
  }

  private _initSimulatorCosts = {
    divc: {
      leaseCars: 0,
      privateCars: 0,
      users: 0,
      cost: 0
    },
    mrt: {
      connectedUsers: 0,
      orders: 0,
      users: 0,
      cost: 0
    },
    ord: {
      orders: 0,
      users: 0,
      cost: 0
    },
    rei: {
      users: 0,
      cost: 0
    },
    total: {
      cost: 0
    }
  }

  public constructor(
    private store: Store<AppState>,
    @Inject('accountService') private accountService: AccountService,
    @Inject('configService') private configService: ConfigService
  ) {
  }

  public ngOnInit(): void {
    this.total = this._initSimulatorCosts;

    this.store.select('user', 'currentUser').subscribe((res): void => {
      this.requestedPlatforms = res.requestedPlatforms.reduce((acc, plt): any => ({ ...acc, [plt]: true }), {});
    });
  }

  public handleSimulatorUpdate(event): void {
    this._simulatorStatus[event.id] = event.value;
    this.configService.simulatorCalculate(this._simulatorStatus).subscribe((res): void => this.total = res);
  }

}
