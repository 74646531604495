import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { getAPI, postAPI, reCaptchaAction } from '../services.utils';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  public constructor(private http: HttpClient) {
  }

  public isMailExists(mail: string): Observable<boolean> {
    return reCaptchaAction('phaseOne_checkEmail', (token): Observable<any> => {
      return getAPI(this.http, 'public/register/existsEmail', { email: mail, reCAPTCHA: token });
    });
  }

  public isVatExists(vat: string): Observable<boolean> {
    return reCaptchaAction('phaseOne_checkVat', (token): Observable<any> => {
      return getAPI(this.http, 'public/register/existsVat', { vat, reCAPTCHA: token });
    });
  }

  public publicCreateAccount(formData): Observable<string> {
    return reCaptchaAction('phaseOne_createAccount', (token): Observable<any> => {
      return postAPI(this.http, 'public/register/createAccount', formData, {
        params: {
          reCAPTCHA: token
        }
      });
    });
  }

  public getRegistrationCompany(): Observable<CompanyDto> {
    return getAPI(this.http, 'register/company');
  }

  public getConditions(lang: string): Observable<ConditionsDto> {
    return getAPI(this.http, 'register/conditions', { language: lang });
  }

  public postInitialSite(body: SiteDto): Observable<any> {
    return postAPI(this.http, 'register/createInitialSite', body);
  }

}

/** url = string */
type URL = string

export interface ConditionsDto {
  general: URL;
  ord: URL;
  fms: URL;
  divc: URL;
  rei: URL;
  mgt: URL;
}

export interface CompanyDto {
  guid: string;
  address: AddressDto;
  fleetsize: string;
  language: string;
  name: string;
  requestedPlatforms: string[];
  selfregisrationStatus: string;
  type: string;
  vat: string;
}

export interface SiteDto {
  accountId: string;
  address: AddressDto;
  applications: ApplicationDto[];
  brandId: string;
  name: string;
  useCompanyAddress: boolean;
}

export interface AddressDto {
  country: AddressCountryEnum;
  number: string;
  street: string;
  town: string;
  zipCode: string;
}

export interface ApplicationDto {
  BIC?: string;
  FSMANumber?: string;
  IBAN?: string;
  hourlyRate?: number;
  type: ApplicationTypeEnum;
}


enum AddressCountryEnum {'BE', 'FR', 'LU', 'DE'}

enum ApplicationTypeEnum {'FMS', 'ORD', 'MRT', 'REI', 'DIVc'}

