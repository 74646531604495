import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiValidator } from '../../../validators/api.validator';
import { finalize, Observable } from 'rxjs';
import { ConfigService } from '../../../services/config/config.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { AccountService } from '../../../services/account/account.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {

  // will be usefull when TIRE CENTER has to be implemented again.
  // public companyTypes: string[] = ['BROKER','DEALER','FLEET_OWNER','LEASE_COMPANY','TYRE_CENTER'];

  public isLinear = true;
  public platforms = [
    {
      title: 'MRT',
      description: 'MRT_DETAIL'
    }, {
      title: 'ORD',
      description: 'ORD_DETAIL'
    }, {
      title: 'REI',
      description: 'REI_DETAIL'
    }, {
      title: 'DIVc',
      description: 'DIVc_DETAIL'
    }, {
      title: 'FMS',
      description: 'FMS_DETAIL'
    }
  ];
  public companyTypes: string[] = ['BROKER', 'DEALER', 'FLEET_OWNER', 'LEASING_COMPANY'];
  public companyTypeTooltip = '';
  public formGroups: UntypedFormGroup = new UntypedFormGroup({});
  public isCreating: boolean = false;

  public $supportedCountries: Observable<string[]>;
  public $supportedLanguages: Observable<string[]>;
  public $supportedGenders: Observable<string[]>;

  public relevantPlatforms: { title: string; description: string }[];

  public constructor(
    @Inject('accountService') public accountService: AccountService,
    @Inject('configService') public configService: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>
  ) {
    this.$supportedCountries = this.store.select('app', 'config', 'countries');
    this.$supportedLanguages = this.store.select('app', 'config', 'languages');
    this.$supportedGenders = this.store.select('app', 'config', 'genders');
  }

  public static hasExtraField(value: any): boolean {
    if (typeof value != 'string') {
      return false;
    }
    return new RegExp(/\?.*=.*&/, 'g').test(value);
  }

  public static extractExtraFieldsFromString(value: string, key: string): { [key: string]: string } {
    const regRes = new RegExp(/([a-zA-Z0-9_\-]*)\?([a-zA-Z0-9_\-]*)=(.*)&/, 'g').exec(value);
    return {
      [regRes[2]]: regRes[3],
      [key]: regRes[1]
    };
  }

  public ngOnInit(): void {
    this.formGroups.addControl('clientInfo', new UntypedFormGroup({
      accountType: new UntypedFormControl('', [Validators.required]),
      fleetSize: new UntypedFormControl('', [Validators.required, Validators.pattern(/^[0-9]+$/)]),
      vat: new UntypedFormControl(
        '',
        [Validators.required, Validators.rsVatValidator],
        ApiValidator((vat: string): Observable<boolean> => this.accountService.isVatExists(vat))
      ),
      name: new UntypedFormControl('', [Validators.required, Validators.maxLength(30)]),
      invoiceAddress: new UntypedFormGroup({
        street: new UntypedFormControl('', [Validators.required]),
        number: new UntypedFormControl('', [Validators.required]),
        zipCode: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]{4,5}')]),
        town: new UntypedFormControl('', [Validators.required]),
        country: new UntypedFormControl('', [Validators.required])
      })
    }));

    this.formGroups.addControl('contactInfo', new UntypedFormGroup({
      '#exclude-companyManagerInfo': new UntypedFormControl(false, [Validators.required, Validators.requiredTrue]),
      gender: new UntypedFormControl('', [Validators.required]),
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      jobTitle: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email], ApiValidator((mail: string): Observable<boolean> => this.accountService.isMailExists(mail))),
      phone: new UntypedFormControl('', {
        validators: [Validators.required, Validators.pattern('((\\+|00)(32|33|352)|0){0,1}[0-9]{3,9}')],
        updateOn: 'blur'
      }),
      mobile: new UntypedFormControl('', {
        validators: [Validators.pattern('((\\+|00)(32|33|352)|0){0,1}[0-9]{6,9}')],
        updateOn: 'blur'
      }),
      language: new UntypedFormControl('', [Validators.required])
    }));

    this.formGroups.addControl('appInfo', new UntypedFormGroup({
      platforms: new UntypedFormGroup(
        this.platforms.reduce((
          acc,
          description
        ) => ({
          ...acc,
          [description.title]: new UntypedFormControl({
            value: false,
            disabled: description.title == 'REI'
          })
        }), {}),
        (control: AbstractControl) => (Object.values(control.value as object).some((bool): boolean => !!bool) ? null : {
          required: false,
          description: 'select at least one platform'
        })
      )
    }));

    this.formGroups
      .get('clientInfo.accountType')
      .valueChanges
      .subscribe((res: string): void => {
        this.companyTypeTooltip = res + '_DETAIL';
        res == 'FLEET_OWNER' ? this.formGroups.get('clientInfo.fleetSize').enable() : this.formGroups.get('clientInfo.fleetSize').disable();
        this.relevantPlatforms = this.getRelevantPlatforms(res);
      });

    this.formGroups.get('appInfo.platforms').valueChanges.subscribe((res): void => {
      const checkRei = res['ORD'] || res['MRT'];
      const rei = this.formGroups.get('appInfo.platforms.REI');
      if (checkRei !== rei.value) {
        rei.setValue(checkRei);
      }
    });
  }

  public platformsCheck(platformTitle: string): void {
    if (platformTitle === 'REI') {
      return;
    } // Cannot be checked alone
    const val = !this.formGroups.get('appInfo.platforms.' + platformTitle).value;
    this.formGroups.get('appInfo.platforms.' + platformTitle).setValue(val);
  }

  public getRelevantPlatforms(companyType: string): { title: string, description: string }[] {
    switch (companyType) {
      case 'DEALER':
        return this.platforms.filter((description): boolean => description.title == 'MRT' || description.title == 'ORD' || description.title == 'REI');
      case 'TYRE_CENTER':
        return this.platforms.filter((description): boolean => description.title == 'MRT' || description.title == 'REI');
      case 'BROKER':
        return this.platforms.filter((description): boolean => description.title == 'DIVc');
      case 'FLEET_OWNER':
        return this.platforms.filter((description): boolean => description.title == 'FMS');
      case 'LEASING_COMPANY':
        return this.platforms.filter((description): boolean => description.title == 'MRT' || description.title == 'ORD' || description.title == 'REI' || description.title == 'FMS');
    }
  }

  public handleSumbit(): void {
    this.isCreating = true;

    const {
      appInfo,
      clientInfo,
      contactInfo
    } = this.formGroups.value;

    const requestedPlatform =
      Object
        .keys(appInfo.platforms as object)
        .filter((platform): boolean => {
          if (platform == 'REI') {
            return false;
          }
          return !!appInfo.platforms[platform];
        });

    const companyManager = Object.keys(contactInfo as object).reduce((previousValue, currentValue) => contactInfo[currentValue] ? ({
      ...previousValue,
      [currentValue]: contactInfo[currentValue]
    }) : previousValue, {});

    const formData = {
      requestedPlatform,
      language: contactInfo.language,
      companyManager,
      ...clientInfo
    };
    delete formData.companyManager['#exclude-companyManagerInfo'];

    this.accountService
      .publicCreateAccount(formData)
      .pipe(
        finalize(() => {
          this.isCreating = false;
        })
      )
      .subscribe({
        next: (): any => this.router.navigate(['../check-out'], { relativeTo: this.route }),
        error: (err): any => console.error(err)
      });
  }

  public handleNext(controlName: string): void {
    this.formGroups.get(controlName).markAllAsTouched();
  }

  public checkError(controlName: string): boolean {
    return this.formGroups.get(controlName).invalid && this.formGroups.get(controlName).touched;
  }
}
