import { ActionEx, UserActionTypes } from './user.actions';
import { CompanyDto } from '../../services/account/account.service';

export interface State {
  currentUser: CompanyDto;
}

export const initialState: State = {
  currentUser: null
};

export function userReducer(
    state = initialState,
    action: ActionEx
): State {
  switch (action.type) {

    case UserActionTypes.setCurrentUser:
      return {
        ...state,
        currentUser: action.payload,
      };

    default:
      return state;
  }
}
