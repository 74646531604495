import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from 'src/environments/environment';
import { RsSentry } from '@lib/utils/rs-senty-init';

new RsSentry({
  dsn: 'https://d0e04db835d642e682dd8e0a6d85fead@sentry.io/1893917',
  enabled: environment.enableSentryMonitoring,
  environment: environment.environment,
  sentryReleaseName: environment.sentryReleaseName
}).sentryInit();

Sentry.configureScope((scope): void => {
  scope.setTag('app.environment', environment.environment);
});

Sentry.configureScope((scope): void => {
  scope.setTag('app.name', 'Registration');
});

@Injectable()
export class SentryErrorHandlerService implements ErrorHandler {
  public constructor() {
  }

  public handleError(error: any): void {
    Sentry.captureException(error.originalError || error);

    // Show popup to collect user feedback
    // const eventId = Sentry.captureException(error.originalError || error);
    // Sentry.showReportDialog({ eventId });
  }
}
