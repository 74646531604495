import { Component, Inject } from '@angular/core';
import { ConfigService } from './services/config/config.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'registration-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public title = 'renta-registration';
  public topBarEnvironment: string;
  public accountIsAllowed = false;
  public readonly appVersion: number;

  public constructor(
    @Inject('configService') public configService: ConfigService
  ) {
    this.appVersion = environment.version!;
  }

}
