import { Component, Inject, OnInit } from '@angular/core';
import { ConfigService } from '../../services/config/config.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { forkJoin } from 'rxjs';
import * as appActions from '../../reducers/app/app.actions';

@Component({
  selector: 'app-phase-one',
  templateUrl: './phase-one.component.html',
  styleUrls: ['./phase-one.component.scss']
})
export class PhaseOneComponent implements OnInit {

  public constructor(
    @Inject('configService') public configService: ConfigService,
    private store: Store<AppState>,
  ) {
  }

  public ngOnInit(): void {

    forkJoin(
      this.configService.getPublicSupportedCountries(),
      this.configService.getPublicSupportedLanguages(),
      this.configService.getPublicSupportedGenders()
    )
      .subscribe((response: [string[], string[], string[]]): void => {
        this.store.dispatch(new appActions.SetCountries(response[0]));
        this.store.dispatch(new appActions.SetLanguages(response[1]));
        this.store.dispatch(new appActions.SetGenders(response[2]));
      });
  }

}

