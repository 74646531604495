<div
  [formGroup]="formGroups.get('applications')"
  class="registration-forms pb-10px"
>
  <div class="row">
    <div class="col-sm-12 col-12">
      <div class="w-100 form-field">
        <div *ngIf="requestedPlatforms$ | async; let platforms">

          <app-permission
            *ngIf="platforms['MRT']"
            [control]="formGroups.get('applications.MRT')"
            color="rs-color--blue--maya"
            subtitle="MRT_DETAIL"
            title="MRT"
          ></app-permission>

          <app-permission
            *ngIf="platforms['ORD']"
            [control]="formGroups.get('applications.ORD')"
            color="rs-color--green--granny-smith"
            subtitle="ORD_DETAIL"
            title="ORD"
          ></app-permission>

          <app-permission
            *ngIf="platforms['ORD'] || platforms['MRT']"
            [control]="formGroups.get('applications.REI')"
            [disable]="true"
            [open]="formGroups.get('applications.MRT').enabled || formGroups.get('applications.ORD').enabled"
            color="rs-color--red--brink-pink"
            subtitle="REI_DETAIL"
            title="REI"
          ></app-permission>

          <app-permission
            *ngIf="platforms['DIVc']"
            [control]="formGroups.get('applications.DIVc')"
            color="rs-color--green--granny-smith"
            subtitle="DIVc_DETAIL"
            title="DIVc"
          ></app-permission>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="showBrandSelect"
    class="mt-30px"
    title="BRAND"
  >
    <div class="row">
      <div class="col-sm-12 col-12">
        <mat-form-field class="w-100 form-field">
          <mat-label>{{'MAIN_BRAND' | translate}}</mat-label>
          <mat-select [formControl]="formGroups.get('brandId')">
            <mat-option
              *ngFor="let brand of brands$ | async"
              [value]="brand.id"
              class="mat-option-capitalized"
            >
              {{brand.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="header-info">
      <p>{{ 'MULTIBRAND_ENTITY' | translate }}</p>
    </div>
  </div>
  <div class="mt-15px">
    <div class="row">
      <div class="col-sm-12 col-12">
        <mat-checkbox [formControl]="formGroups.get('legal')">
          {{'I_READ_AND_ACCEPT_LEGAL' | translate}}
        </mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div
        *ngFor="let doc of platformLegalDocuments"
        class="document-link-wrapper"
      >
        <a
          (click)="showDocument(doc.data)"
          class="document-link"
        >{{doc.name | translate}}</a>
      </div>
    </div>
  </div>
  <div class="d-flex mt-20px">
    <button
      (click)="handleSubmit()"
      [disabled]="validatePhaseTwo()"
      [rsSpinnerButtonShow]="isCreating"
      class="rs-btn--primary"
      mat-button
    >
      {{ 'COMPLETE' | translate }}
      <mat-icon
        *ngIf="!validatePhaseTwo()"
        iconPositionEnd
      >done
      </mat-icon>
      <mat-icon
        *ngIf="validatePhaseTwo()"
        iconPositionEnd
      >remove
      </mat-icon>
    </button>
  </div>
</div>