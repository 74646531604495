import { Component, Inject, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfigService } from '../../../services/config/config.service';
import { AccountService, CompanyDto, ConditionsDto, SiteDto } from '../../../services/account/account.service';
import { filter, map, skip, skipWhile, startWith, switchMap, take, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FilesService } from '../../../services/files/files.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import * as userActions from '../../../reducers/user/user.actions';

@Component({
  selector: 'app-setup-two',
  templateUrl: './setup-two.component.html',
  styleUrls: ['./setup-two.component.scss']
})
export class SetupTwoComponent implements OnInit {

  public showBrandSelect: boolean = false;
  public formGroups: UntypedFormGroup = new UntypedFormGroup({});
  public platformLegalDocuments = [];
  public activePlatforms: string[];

  public brands$: Observable<{ id: string; name: string }[]>;
  public countries$: Observable<string[]>;
  public account$: Observable<CompanyDto>;
  public requestedPlatforms$: Observable<{ [label: string]: boolean }>

  public companyName$: Observable<string>;
  public companyAddress$: Observable<string>;

  public isCreating = false;

  public constructor(
    public dialog: MatDialog,
    private router: Router,
    private _translate: TranslateService,
    private store: Store<AppState>,
    @Inject('configService') private configService: ConfigService,
    @Inject('accountService') private accountService: AccountService,
    @Inject('filesService') private filesService: FilesService
  ) {
  }

  public ngOnInit(): void {

    //Create PLATFORMS FORM control
    this.formGroups
      .addControl(
        'applications',
        new UntypedFormGroup({
          MRT: new UntypedFormGroup({
            hourlyRate: new UntypedFormControl('', [Validators.required]),
            IBAN: new UntypedFormControl('', [Validators.required, Validators.pattern(/[a-zA-Z]{2}[0-9]{6,}/)]),
            BIC: new UntypedFormControl('', [Validators.required, Validators.pattern(/[a-zA-Z]{6}[a-zA-Z0-9]{2,}/)])
          }, {}),
          ORD: new UntypedFormGroup({
            IBAN: new UntypedFormControl('', [Validators.required, Validators.pattern(/[a-zA-Z]{2}[0-9]{6,}/)]),
            BIC: new UntypedFormControl('', [Validators.required, Validators.pattern(/[a-zA-Z]{6}[a-zA-Z0-9]{2,}/)])
          }),
          REI: new UntypedFormControl({}),
          DIVc: new UntypedFormGroup({
            FSMANumber: new UntypedFormControl('', [Validators.required])
          })
        })
      );
    //Create BRAND DROPDOWN control
    this.formGroups.addControl('brandId', new UntypedFormControl('', [Validators.required]));
    //Create LEGAL CHECKBOX control
    this.formGroups.addControl('legal', new UntypedFormControl('', [Validators.requiredTrue]));

    //Set all needed OBSERVABLES
    this.brands$ = this.configService.getBrands();
    this.countries$ = this.configService.getSupportedCountries();
    this.account$ = this.store.select('user', 'currentUser');
    this.companyName$ = this.account$.pipe(map((res): string => res.name))
    this.companyAddress$ = this.account$.pipe(map((res): string => `${res.address.street} ${res.address.number}, ${res.address.zipCode} ${res.address.town}, ${res.address.country}`));
    this.requestedPlatforms$ = this.account$.pipe(map((res): any => res.requestedPlatforms.reduce((acc, platform): any => ({
      ...acc,
      [platform]: true
    }), {})));

    //Get PLATFORMS FORM control
    const platforms: UntypedFormGroup = this.formGroups.get('applications') as UntypedFormGroup;

    //Disable all platforms at init
    Object.values(platforms.controls).forEach((res): void => res.disable());

    //Listening to PLATFORMS control VALUE CHANGES
    const formChanges = platforms.valueChanges.pipe(
      //Map the current platfroms being enabled and if all are disabled then we have to check the status of the group instead ( https://github.com/angular/angular/issues/15982 ).
      map((enabledPlatforms: {}): string[] => platforms.enabled && Object.keys(enabledPlatforms) || []),
      //Check if the selected platforms did change or if it's just the values inside them ( if the latter is true stop the observable here ).
      filter((enabledPlatforms: string[]): boolean => {
        return this.activePlatforms && this.activePlatforms.join(',') != enabledPlatforms.join(',') || !this.activePlatforms;
      }),
      tap((enabledPlatforms: string[]): void => {
        this.activePlatforms = enabledPlatforms;
        //Show brand select when required ( MRT or ORD is active ).
        this.showBrandSelect = platforms.controls['MRT'].enabled || platforms.controls['ORD'].enabled;
        this.showBrandSelect ? this.formGroups.get('brandId').enable() : this.formGroups.get('brandId').disable();
        //Reset terms and conditions checkbox ( Is this needed ? ).
        this.formGroups.get('legal').setValue(false);
      })
    );

    const langChanges = this._translate.onLangChange.pipe(
      skip(1),
      startWith({ lang: this._translate.currentLang }),
      switchMap(({ lang }: { lang: 'fr' | 'nl' | 'en' | 'de' }): Observable<any> => {
        return this.store.select('app', 'conditionDocuments', lang)
          .pipe(
            skipWhile((res): boolean => !res),
            take(1),
            map((res): { lang: string; platforms: ConditionsDto } => ({ lang, platforms: res }))
          )
      })
    )

    combineLatest([formChanges, langChanges])
      .pipe(
        map(([activePlatforms, conditions]: any[]): any[] => {
          const activeDocuments = activePlatforms.concat(['MGT']);
          if (!activeDocuments.includes('DIVc')) {
            activeDocuments.push('general');
          }
          const docs = activeDocuments
            .map((platform): any => {
              return {
                name: `LEGAL_${platform.toUpperCase()}_SPEC`,
                lang: conditions.lang,
                data: conditions.platforms[platform.toLowerCase()]
              }
            })
          return [].concat(docs);
        })
      )
      .subscribe((docs): void => {
        setTimeout((): void => {
          this.platformLegalDocuments = docs;
        }, 0);
      });
  }

  public showDocument(data: string): void {
    this.filesService.openBase64PDF(data);
  }

  public handleDisable(disabled: boolean, path: string): void {
    const control = this.formGroups.get(path);
    disabled ? control.disable() : control.enable();
  }

  public validatePhaseTwo(): boolean {
    const controls = (this.formGroups.get('applications') as UntypedFormGroup).controls;
    return !this.formGroups.valid || !Object.values(controls).some((abstractControl): boolean => abstractControl.enabled);
  }

  public platformChange(platformValue: { id: string }): void {
    const platform = this.formGroups.get('applications.' + platformValue.id);
    if (!platform) {
      return;
    }
    if (Object.keys(platformValue).length <= 1) {
      return platform.disable();
    }

    platform.enable();
    Object.keys(platformValue).forEach((field): void => {
      const foundField = platform.get(field);
      if (!foundField) {
        return;
      }
      foundField.setValue(platformValue[field]);
    })
  }

  public handleSubmit(): void {

    const applications = Object.keys(this.formGroups.value.applications as object)
      //REI is empty and should not be included in the call
      .filter((key): boolean => !!Object.keys(this.formGroups.value.applications[key] as object).length)
      .map((key): any => ({
        ...this.formGroups.value.applications[key],
        type: key
      }));

    const initialSite: SiteDto = {
      ...this.formGroups.value.info,
      applications
    };

    initialSite.useCompanyAddress = !initialSite.address;

    if (this.formGroups.value.brandId) {
      initialSite.brandId = this.formGroups.value.brandId;
    }

    this.account$
      .pipe(take(1))
      .subscribe((res): void => {
        initialSite.accountId = res.guid;
        initialSite.name = initialSite.name || res.name;
        this.isCreating = true;
        this.postInitialSite(initialSite);
      });
  }

  private postInitialSite(initialSite: SiteDto): void {
    this.accountService.postInitialSite(initialSite)
      .pipe(
        switchMap((): Observable<CompanyDto> => this.accountService.getRegistrationCompany()),
        tap((res): void => {
          this.store.dispatch(new userActions.SetCurrentUser(res));
          this.isCreating = false;
        })
      )
      .subscribe((): void => {
        this.router.navigate(['/portal/success']);
      });
  }

}
