import { Action } from '@ngrx/store';
import { CompanyDto } from '../../services/account/account.service';

export enum UserActionTypes {
  setCurrentUser = 'Set current user', // eslint-disable-line no-unused-vars
}

export class ActionEx implements Action {
  public readonly type: UserActionTypes;
  public payload: any;
}

export class SetCurrentUser implements ActionEx {
  public readonly type = UserActionTypes.setCurrentUser;

  public constructor(public payload: CompanyDto) {
  }
}
