<!-- <h2 class="mb-30px">Simulator</h2> -->

<div
  *ngIf="total"
  class="pt-10px"
>
  <app-pricing-card
    (valueChanges)="handleSimulatorUpdate($event)"
    *ngIf="requestedPlatforms['MRT']"
    [values]="total['mrt']"
    cardTitle="MRT"
    platform="mrt"
    subtitle="MRT_FULL"
  >
    <input
      info="NUMBER_OF_USERS_INFO"
      name="users"
      placeholder="USERS"
      pricingCardInput
      type="number"
    />
    <input
      info="NUMBER_OF_WORK_ORDERS_INFO"
      name="orders"
      placeholder="WORK_ORDERS"
      pricingCardInput
      type="number"
    />
    <input
      info="CONNECTED_USERS_INFO"
      name="connectedUsers"
      placeholder="CONNECTED_USERS"
      pricingCardInput
      type="number"
    />
  </app-pricing-card>

  <app-pricing-card
    (valueChanges)="handleSimulatorUpdate($event)"
    *ngIf="requestedPlatforms['ORD']"
    [values]="total['ord']"
    cardTitle="ORD"
    platform="ord"
    subtitle="ORD_FULL"
  >
    <input
      info="NUMBER_OF_USERS_INFO"
      name="users"
      placeholder="USERS"
      pricingCardInput
      type="number"
    />
    <input
      info="NUMBER_OF_ORDERS_INFO"
      name="orders"
      placeholder="ORDERS"
      pricingCardInput
      type="number"
    />
  </app-pricing-card>

  <app-pricing-card
    (valueChanges)="handleSimulatorUpdate($event)"
    *ngIf="requestedPlatforms['REI']"
    [values]="total['rei']"
    cardTitle="REI"
    platform="rei"
    subtitle="REI_FULL"
  >
    <input
      info="NUMBER_OF_USERS_INFO"
      name="users"
      placeholder="USERS"
      pricingCardInput
      type="number"
    />
  </app-pricing-card>

  <app-pricing-card
    (valueChanges)="handleSimulatorUpdate($event)"
    *ngIf="requestedPlatforms['DIVc']"
    [values]="total['divc']"
    cardTitle="DIVc"
    platform="divc"
    subtitle="DIVc_FULL"
  >
    <input
      info="NUMBER_OF_USERS_INFO"
      name="users"
      placeholder="USERS"
      pricingCardInput
      type="number"
    />
    <input
      info="NUMBER_OF_LEASE_CARS_INFO"
      name="leaseCars"
      placeholder="LEASE_CARS"
      pricingCardInput
      type="number"
    />
    <input
      info="NUMBER_OF_PRIVATE_CARS_INFO"
      name="privateCars"
      placeholder="PRIVATE_CARS"
      pricingCardInput
      type="number"
    />
  </app-pricing-card>

</div>

<div class="full-total">
  <p class="text-end full-total-label">{{ 'TOTAL_YEARLY_COST' | translate }}</p>
  <p class="text-end full-total-price">{{ total?.total?.cost || '-,--' }} €</p>
</div>

<div class="d-flex justify-content-end">
  <button
    class="rs-btn--primary"
    mat-button
    routerLink="../platforms"
  >
    {{ 'NEXT' | translate }}
    <mat-icon iconPositionEnd>keyboard_arrow_right</mat-icon>
  </button>
</div>