<form>
  <div
    *ngIf="headerTemplate"
    class="header-container"
  >
    <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
  </div>

  <!-- <ng-container [ngTemplateOutlet]="bodyTemplate"></ng-container> -->
  <ng-content></ng-content>

  <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
</form>