import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilesService } from '../../services/files/files.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { Documents } from '../../reducers/app/app.reducer';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-stylised-snackbar',
  templateUrl: './stylised-snackbar.component.html',
  styleUrls: ['./stylised-snackbar.component.scss']
})
export class StylisedSnackbarComponent implements OnInit, OnDestroy {
  public conditionDocuments: Documents;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: { message: string; platform: string; link: string | any[] },
    @Inject('filesService') public filesService: FilesService,
    public dialogRef: MatDialogRef<StylisedSnackbarComponent>,
    private store: Store<AppState>,
    public translateService: TranslateService
  ) {
  }

  public ngOnInit(): void {
    this.getConditions();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public getConditions(): void {
    this.store.select('app', 'conditionDocuments')
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        (conditionDocuments: Documents): void => {
          this.conditionDocuments = conditionDocuments;
        }
      );
  }

}
