/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  RsAuthenticationInterceptor
} from '@rs-shared-lib/modules/rs-core/modules/rs-authentication/interceptors/rs-authentication.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RsAuthenticationInterceptor,
    multi: true
  }
];
