<div
  *ngIf="!hidden"
  [class.selected]="selected"
  class="list-select {{platform || ''}}"
>
  <mat-expansion-panel
    (closed)="handleDeselected()"
    (opened)="handleSelected()"
    hideToggle
  >

    <mat-expansion-panel-header class="header">
      <mat-panel-title class="title">
        {{ title | translate }}
      </mat-panel-title>
      <mat-panel-description class="description">
        <div class="d-flex align-items-center">
          <p>{{ description | translate }}</p>
          <mat-slide-toggle
            [checked]="selected"
            class="list-select-checkbox"
            disabled="true"
          ></mat-slide-toggle>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="body">
      <ng-content></ng-content>
    </div>
  </mat-expansion-panel>
</div>