import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MockAccountService {

  constructor() {
  }

  public isMailExists(_mail: string): Observable<{ body?: any; statusCode?: string; statusCodeValue?: number; }> {
    return of({ body: {}, statusCode: '400 unauthorized', statusCodeValue: 200 })
  }

  public isVatExists(_vat: string): Observable<{ body?: any; statusCode?: string; statusCodeValue?: number; }> {
    return of({ body: {}, statusCode: '200 OK', statusCodeValue: 200 })
  }

  public publicCreateAccount(): Observable<{ status: number; }> {
    return of({ status: 201 }).pipe(delay(2500));
  }
}
