/* "Barrel" of services */
import { ErrorHandler } from '@angular/core';
import { SentryErrorHandlerService } from './sentry-error-handler/sentry-error-handler.service';
import { environment } from '../../environments/environment';
import { AccountService } from './account/account.service';
import { MockAccountService } from './account/account.mockup.service';
import { ConfigService } from './config/config.service';
import { FilesService } from './files/files.service';
import { AuthService } from './auth/auth.service';

export const serviceProviders = [
  AuthService,
  {
    provide: ErrorHandler,
    useClass: environment.enableSentryMonitoring ? SentryErrorHandlerService : ErrorHandler
  },
  {
    provide: 'accountService',
    useClass: environment.useMockedServices ? MockAccountService : AccountService
  },
  {
    provide: 'configService',
    useClass: environment.useMockedServices ? ConfigService : ConfigService
  },
  {
    provide: 'filesService',
    useClass: environment.useMockedServices ? FilesService : FilesService
  }
];
