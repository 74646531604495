<div class="perms-item {{color}}">
  <div
    [class.opened]="fields.length && toggleControl.value"
    class="perms-item-header"
  >
    <div class="col p-0">
      <div class="d-flex align-items-center">
        <h3 class="{{color}}">{{title | translate}}</h3>
        <p class="profile-perms-subtitle">{{subtitle | translate}}</p>
      </div>
    </div>
    <mat-slide-toggle
      [formControl]="toggleControl"
      class="perms-item-toggle"
    >
    </mat-slide-toggle>
  </div>
  <div
    *ngIf="fields.length"
    [@openClose]="toggleControl.value ? 'open' : 'closed'"
    class="perms-item-details"
  >
    <form
      [formGroup]="this.control"
      class="row"
    >
      <div
        *ngFor="let formField of fields"
        class="col-lg-3 col-md-4 col-sm-6 col-12"
      >
        <ng-container [ngSwitch]="formField">
          <mat-form-field
            *ngSwitchCase="'hourlyRate'"
            class="w-100 dynamic-form-text"
          >
            <mat-label>{{ formField | translate }}</mat-label>
            <input
              [formControlName]="formField"
              appCurrency
              matInput
              type="text"
            />
          </mat-form-field>
          <mat-form-field
            *ngSwitchCase="'IBAN'"
            class="w-100 dynamic-form-text"
          >
            <mat-label>{{ formField | translate }}</mat-label>
            <input
              [formControlName]="formField"
              appIBAN
              matInput
            />
          </mat-form-field>
          <mat-form-field
            *ngSwitchCase="'BIC'"
            class="w-100 dynamic-form-text"
          >
            <mat-label>{{ formField | translate }}</mat-label>
            <input
              [formControlName]="formField"
              appBIC
              matInput
              type="text"
            />
          </mat-form-field>
          <mat-form-field
            *ngSwitchDefault
            class="w-100 dynamic-form-text"
          >
            <mat-label>{{ formField | translate }}</mat-label>
            <input
              [formControlName]="formField"
              matInput
            />
          </mat-form-field>
        </ng-container>
      </div>
    </form>
  </div>
</div>
