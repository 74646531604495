import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';


@Injectable()
export class AuthService {
  public constructor(
    private cookieService: CookieService,
    private jwtHelper: JwtHelperService,
  ) {
    // this.isUserAuthenticated();
  }

  /** Get sso token.
   *
   * @param none
   * @return token: string
   */
  public getToken(): string {
    return this.cookieService.get('sso');
  }

  /** Check token existence and expiration.
   *
   * @param none
   * @return boolean
   */
  public isUserAuthenticated(): boolean {
    const token = this.getToken();
    return (token && token.length > 0 && !this.jwtHelper.isTokenExpired(token));
  }

}
