import { Component, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-form-window',
  templateUrl: './form-window.component.html',
  styleUrls: ['./form-window.component.scss']
})
export class FormWindowComponent {

  @ContentChild('header') headerTemplate: TemplateRef<any>;
  @ContentChild('footer') footerTemplate: TemplateRef<any>;

  constructor() {
  }
}
