<mat-vertical-stepper
  #stepper
  [linear]='isLinear'
  class='registration-forms'
  labelPosition='bottom'
>

  <mat-step [stepControl]='formGroups.controls?.clientInfo'>
    <ng-template matStepLabel>{{ 'COMPANY' | translate }}</ng-template>
    <div class=' pb-10px'>
      <app-form-window [formGroup]="formGroups.get('clientInfo')">
        <ng-template #header>
          <div class='company-info-header'>
            <mat-icon class='me-20px'>info</mat-icon>
            <div class='stepper-text-container'>
              <p
                class='rs-font--primary-light'
                innerHTML="{{ 'REGISTRATION_PROCESS' | translate }}"
              ></p>
            </div>
          </div>
        </ng-template>
        <app-form-section>
          <div class='row'>
            <div
              [class.col-md-6]="companyTypeTooltip == 'FLEET_OWNER_DETAIL'"
              [class.col-md-7]="companyTypeTooltip != 'FLEET_OWNER_DETAIL'"
              class='col-sm-8 col-md-7 col-lg-6 col-12'
            >
              <div class='row'>
                <mat-form-field class='col form-field'>
                  <mat-label>{{'COMPANY_TYPE' | translate}}</mat-label>
                  <mat-select formControlName='accountType'>
                    <mat-option
                      *ngFor='let type of companyTypes'
                      value='{{type}}'
                    >
                      {{ type | translate}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div
              *ngIf="companyTypeTooltip == 'FLEET_OWNER_DETAIL'"
              class='col-lg-4 col-md-6 col-sm-8 col-12'
            >
              <div class='row'>
                <mat-form-field class='col form-field'>
                  <mat-label>{{ 'APROXIMATE_FLEET_SIZE' | translate }}</mat-label>
                  <input
                    formControlName='fleetSize'
                    matInput
                    type='number'
                  >
                </mat-form-field>
                <mat-icon
                  *ngIf='companyTypeTooltip'
                  class='pe-15px align-self-center material-icons-outlined info-tooltip'
                  matTooltip="{{'APROXIMATE_FLEET_SIZE_INFO' | translate}}"
                  matTooltipPosition='above'
                >info
                </mat-icon>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-sm-8 col-md-7 col-lg-6 col-12'>
              <mat-form-field class='w-100 form-field'>
                <mat-label>{{ 'COMPANY_NAME' | translate }}</mat-label>
                <input
                  capitalize
                  formControlName='name'
                  matInput
                  maxlength='30'
                  type='text'
                >
              </mat-form-field>
            </div>
          </div>
          <div class='row'>
            <div class='col-sm-8 col-md-7 col-lg-6 col-12'>
              <mat-form-field class='w-100 form-field'>
                <mat-label>{{ 'VAT_NUMBER' | translate }}</mat-label>
                <input
                  appVAT
                  formControlName='vat'
                  matInput
                  type='text'
                >
              </mat-form-field>
              <app-form-error
                [error]="{vat: 'PLEASE_USE_A_VALID_VAT_NUMBER', api: 'VAT_NUMBER_IS_ALREADY_LINKED_TO_AN_ACCOUNT', server: 'AN_ERROR_HAS_OCCURED'}"
                [parentForm]='formGroups'
                path='clientInfo.vat'
              >
              </app-form-error>
            </div>
          </div>
          <ng-container formGroupName='invoiceAddress'>
            <div class='row'>
              <div class='col-12 col-sm-8 col-md-9 col-lg-7'>
                <mat-form-field class='w-100 form-field'>
                  <mat-label>{{ 'STREET' | translate }}</mat-label>
                  <input
                    formControlName='street'
                    matInput
                    type='text'
                  >
                </mat-form-field>
              </div>
              <div class='col-6 col-sm-4 col-md-3 col-lg-3'>
                <mat-form-field class='w-100 form-field'>
                  <mat-label>{{ 'STREET_NUMBER' | translate }}</mat-label>
                  <input
                    formControlName='number'
                    matInput
                    type='text'
                  >
                </mat-form-field>
              </div>
            </div>
            <div class='row'>
              <div class='col-6 col-sm-4 col-md-3 col-lg-3'>
                <mat-form-field class='w-100 form-field'>
                  <mat-label>{{ 'ZIP' | translate }}</mat-label>
                  <input
                    formControlName='zipCode'
                    matInput
                    type='text'
                  >
                </mat-form-field>
              </div>
              <div class='col-12 col-sm-8 col-md-9 col-lg-7'>
                <mat-form-field class='w-100 form-field'>
                  <mat-label>{{ 'CITY' | translate }}</mat-label>
                  <input
                    formControlName='town'
                    matInput
                    type='text'
                  >
                </mat-form-field>
              </div>
            </div>
            <div class='row'>
              <div class='col-12 col-sm-8 col-md-7 col-lg-6'>
                <mat-form-field class='w-100 form-field'>
                  <mat-label>{{'COUNTRY' | translate}}</mat-label>
                  <mat-select formControlName='country'>
                    <mat-option
                      *ngFor='let country of $supportedCountries | async'
                      value='{{country}}'
                    >
                      {{country | translate}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </ng-container>
        </app-form-section>
        <ng-template #footer>
          <div class='d-flex justify-content-end'>
            <button
              [disabled]="!formGroups.get('clientInfo').valid"
              class='rs-btn--primary'
              mat-button
              matStepperNext
            >
              {{ 'NEXT' | translate }}
              <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
            </button>
          </div>
        </ng-template>
      </app-form-window>
    </div>
  </mat-step>

  <mat-step [stepControl]='formGroups.controls?.contactInfo'>
    <ng-template matStepLabel>{{ 'CONTACT_INFORMATION' | translate }}</ng-template>
    <div class=' pb-10px'>
      <app-form-window [formGroup]='formGroups.controls?.contactInfo'>
        <ng-template #header>
          <div
            [class.check-error]="checkError('contactInfo.#exclude-companyManagerInfo')"
            class='contact-info-wrapper'
          >
            <mat-checkbox
              class='me-20px'
              formControlName='#exclude-companyManagerInfo'
            >
              <h2 class='rs-label contact-info-label'>{{'I_AM_THE_COMPANY_MANAGER' | translate}}</h2>
              <div>
                <ul>
                  <li>{{ 'TO_SIGN_THE_CONTRACTS' | translate }}</li>
                  <li>{{ 'TO_CHANGE_THE_BANK_INFO' | translate }}</li>
                  <li>{{ 'TO_CREATE_USER_MANAGERS' | translate }}</li>
                </ul>
              </div>
            </mat-checkbox>
          </div>
        </ng-template>
        <app-form-section>
          <div class='row'>
            <div class='col-12'>
              <div class='w-100 form-field mb-10px'>
                <h2 class='rs-label pb-10px'>{{'GENDER' | translate}}</h2>

                <mat-radio-group formControlName='gender'>
                  <mat-radio-button
                    *ngFor='let gender of $supportedGenders | async'
                    [value]='gender'
                    class='me-15px'
                  >
                    {{gender | translate}}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-sm-6 col-12'>
              <mat-form-field class='w-100 form-field'>
                <mat-label>{{ 'FIRST_NAME' | translate }}</mat-label>
                <input
                  formControlName='firstName'
                  matInput
                  type='text'
                >
              </mat-form-field>
            </div>
            <div class='col-sm-6 col-12'>
              <mat-form-field class='w-100 form-field'>
                <mat-label>{{ 'LAST_NAME' | translate }}</mat-label>
                <input
                  formControlName='lastName'
                  matInput
                  type='text'
                >
              </mat-form-field>
            </div>
          </div>
          <div class='row'>
            <div class='col-sm-6 col-12'>
              <mat-form-field class='w-100 form-field'>
                <mat-label>{{ 'EMAIL' | translate }}</mat-label>
                <input
                  formControlName='email'
                  matInput
                  type='text'
                >
              </mat-form-field>
              <app-form-error
                [error]="{api: 'EMAIL_IS_ALREADY_BEING_USED_FOR_ANOTHER_ACCOUNT'}"
                [parentForm]='formGroups'
                path='contactInfo.email'
              >
              </app-form-error>
            </div>
            <div class='col-sm-6 col-12'>
              <mat-form-field class='w-100 form-field'>
                <mat-label>{{ 'JOB_TITLE' | translate }}</mat-label>
                <input
                  capitalize
                  formControlName='jobTitle'
                  matInput
                  type='text'
                >
              </mat-form-field>
            </div>
          </div>
          <div class='row'>
            <div class='col-sm-6 col-12'>
              <mat-form-field class='w-100 form-field'>
                <mat-label>{{ 'PHONE' | translate }}</mat-label>
                <input
                  formControlName='phone'
                  matInput
                  type='text'
                >
              </mat-form-field>
            </div>
            <div class='col-sm-6 col-12'>
              <mat-form-field class='w-100 form-field'>
                <mat-label>{{ 'MOBILE' | translate }}</mat-label>
                <input
                  formControlName='mobile'
                  matInput
                  type='text'
                >
              </mat-form-field>
            </div>
          </div>
          <div class='row'>
            <div class='col-sm-6 col-12'>
              <mat-form-field class='w-100 form-field'>
                <mat-label>{{'LANGUAGE' | translate}}</mat-label>
                <mat-select formControlName='language'>
                  <mat-option
                    *ngFor='let lang of $supportedLanguages | async'
                    value='{{lang}}'
                  >
                    {{('LANG_' + lang) | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </app-form-section>
        <ng-template #footer>
          <div class='d-flex justify-content-between'>
            <button
              class='grey'
              mat-button
              matStepperPrevious
            >
              <mat-icon>keyboard_arrow_up</mat-icon>
              {{ 'BACK' | translate }}
            </button>
            <button
              (click)="handleNext('contactInfo')"
              [disabled]="!formGroups.get('contactInfo').valid"
              class='rs-btn--primary'
              mat-button
              matStepperNext
            >
              {{ 'NEXT' | translate }}
              <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
            </button>
          </div>
        </ng-template>
      </app-form-window>
    </div>
  </mat-step>

  <mat-step [stepControl]='formGroups.controls?.appInfo'>
    <ng-template matStepLabel>{{ 'PLATFORMS' | translate }}</ng-template>
    <app-form-window [formGroup]='formGroups.controls?.appInfo'>
      <app-form-section
        class='platforms'
        formGroupName='platforms'
      >
        <div style='margin-left: -3.5rem;'>
          <div class='platforms-list'>

            <label
              *ngFor='let platform of relevantPlatforms'
              [attr.for]="matSlideToggle.buttonId"
              [ngClass]="'slider-' + platform.title"
              class='platforms-list-row sliders'
            >
              <div class='row'>
                <div class='col-auto px-15px'>
                  <div class='platforms-list-tile platforms-list-platform {{platform.title}}'>
                    <div>
                      <h3>{{platform.title | translate}}</h3>
                      <h4>{{(platform.title + '_FULL') | translate}}</h4>
                    </div>
                  </div>
                </div>
                <div class='col'>
                  <div class='platforms-list-tile platforms-list-detail'>
                    <p>{{platform.description | translate}}</p>
                  </div>
                </div>
                <div class='col-auto px-15px'>
                  <div class='platforms-list-tile platforms-list-toggle {{platform.title}}'>
                    <mat-slide-toggle
                      #matSlideToggle
                      [formControlName]='platform.title'
                    >
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            </label>

          </div>
        </div>
      </app-form-section>
      <ng-template #footer>
        <div
          class='d-flex justify-content-between'
          style='margin-left: -3.5rem'
        >
          <button
            class='grey'
            mat-button
            matStepperPrevious
          >
            <mat-icon>keyboard_arrow_up</mat-icon>
            {{ 'BACK' | translate }}
          </button>
          <button
            (click)='handleSumbit()'
            [disabled]='!formGroups.valid || isCreating'
            [ngClass]="isCreating ? 'loading' : 'rs-btn--primary'"
            [rsSpinnerButtonShow]='isCreating'
            mat-button
          >
            {{ 'CONFIRM' | translate }}
            <mat-icon iconPositionEnd>done</mat-icon>
            <!-- <div class="spinner-border" role="status"></div> -->
          </button>
        </div>
      </ng-template>
    </app-form-window>
  </mat-step>

</mat-vertical-stepper>