/* eslint-disable no-undef */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  public constructor() {
  }

  public openBase64PDF(base64String: string): void {

    const req = new XMLHttpRequest();

    req.onload = function (): void {
      const fileUrl = window.URL.createObjectURL(this.response as Blob);
      window.open(fileUrl);
    }

    req.open('get', 'data:application/pdf;base64,' + base64String, true);
    req.responseType = 'blob';
    req.send();
  }

}
