import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  public constructor() {
  }

  public ngOnInit(): void {
  }

  public redirect(): void {
    window.location.replace(environment.authUrl + '/application/mgt');
  }

}
