import { AppComponent } from './app.component';
import {
  ENVIRONMENT,
  RentaNgxLibModule,
  RsMissingTranslationHandler,
  RsMultiHttpTranslateLoader,
  TranslationService
} from '@lib/rs-ngx';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomMaterialModule } from './modules/custom-material-module/custom-material-module.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './routing/app-routing.module';
import { RsCardSelectComponent } from './components/card-select-input/rs-card-select/rs-card-select.component';
import {
  RsSelectableCardComponent
} from './components/card-select-input/rs-selectable-card/rs-selectable-card.component';
import { PhaseOneComponent } from './pages/phase-one/phase-one.component';
import { CheckOutComponent } from './pages/check-out/check-out.component';
import { FormWindowComponent } from './components/form-window/form-window.component';
import { FormSectionComponent } from './components/form-section/form-section.component';
import {
  RsSelectableCardExtraComponent
} from './components/card-select-input/rs-selectable-card-extra/rs-selectable-card-extra.component';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { PhaseTwoComponent } from './pages/phase-two/phase-two.component';
import { SimulatorComponent } from './pages/phase-two/simulator/simulator.component';
import { PricingCardComponent } from './components/pricing-card/pricing-card.component';
import { PricingCardInputDirective } from './components/pricing-card/pricing-card-input.directive';
import { ListSelectExpandableComponent } from './components/list-select-expandable/list-select-expandable.component';
import { serviceProviders } from './services';
import { httpInterceptorProviders } from './http-interceptors';
import { SetupTwoComponent } from './pages/phase-two/setup/setup-two.component';
import { SetupComponent } from './pages/phase-one/setup/setup.component';
import { StylisedSnackbarComponent } from './components/stylised-snackbar/stylised-snackbar.component';
import { PermissionComponent } from './components/permission/permission.component';
import {
  BICFormatDirective,
  CurrencyFormatDirective,
  IBANFormatDirective,
  VATFormatDirective
} from './directives/bank-inputs.directive';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { SuccessComponent } from './pages/phase-two/success/success.component';
import { StoreModule } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { metaReducers, reducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CapitalizeDirective } from './directives/text-inputs.directive';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './services/auth/auth.service';
import { RsLoaderComponent } from '@rs-shared-lib/components/rs-loader/components/rs-loader.component';


// AoT requires an exported function for TranslateFactory
export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, 'assets/locale/');
}

@NgModule({ declarations: [
  AppComponent,
  RsCardSelectComponent,
  RsSelectableCardComponent,
  PhaseOneComponent,
  CheckOutComponent,
  FormWindowComponent,
  FormSectionComponent,
  RsSelectableCardExtraComponent,
  FormErrorComponent,
  PhaseTwoComponent,
  SimulatorComponent,
  PricingCardComponent,
  PricingCardInputDirective,
  ListSelectExpandableComponent,
  SetupTwoComponent,
  SetupComponent,
  StylisedSnackbarComponent,
  PermissionComponent,
  IBANFormatDirective,
  BICFormatDirective,
  CurrencyFormatDirective,
  VATFormatDirective,
  CapitalizeDirective,
  SuccessComponent
],
bootstrap: [AppComponent],
imports: [
  RsLoaderComponent,
  BrowserModule,
  BrowserAnimationsModule,
  FormsModule,
  ReactiveFormsModule,
  RentaNgxLibModule,
  CustomMaterialModule,
  AppRoutingModule,
  StoreModule.forRoot(reducers, { metaReducers }),
  // Instrumentation must be imported after importing StoreModule (config is optional)
  StoreDevtoolsModule.instrument({
    maxAge: 25,
    logOnly: environment.production, // Restrict extension to log-only mode
    connectInZone: true
  }),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useClass: RsMultiHttpTranslateLoader,
      deps: [HttpClient, ENVIRONMENT, 'appName']
    },
    missingTranslationHandler: {
      provide: MissingTranslationHandler,
      useClass: RsMissingTranslationHandler
    }
  })
],
providers: [
  provideEnvironmentNgxMask(),
  { provide: ENVIRONMENT, useValue: environment },
  {
    provide: 'appName',
    useValue: 'REGISTRATION'
  },
  serviceProviders,
  httpInterceptorProviders,
  AuthService,
  JwtHelperService,
  { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
  provideHttpClient(withInterceptorsFromDi())
] })

export class AppModule {

  public constructor(
    private readonly translateService: TranslationService
  ) {
    this.translateService.initialize();
  }
}
