import { ActionDTO, ActionsList } from './app.actions';
import { ConditionsDto } from '../../services/account/account.service';

export interface Documents {
  fr: ConditionsDto;
  nl: ConditionsDto;
  en: ConditionsDto;
  de: ConditionsDto;
}

export interface AppConfig {
  countries: string[];
  languages: string[];
  genders: string[];
}

export interface State {
  conditionDocuments: Documents;
  config: AppConfig;
}

export const initialState: State = {
  conditionDocuments: {
    fr: null,
    nl: null,
    en: null,
    de: null
  },
  config: {
    countries: null,
    languages: null,
    genders: null
  }
};

export function appReducer(
    state = initialState,
    action: ActionDTO
): State {
  switch (action.type) {

    case ActionsList.setDocuments:

      return {
        ...state,
        conditionDocuments: action.payload
      };

    case ActionsList.setCountries:

      return {
        ...state,
        config: {
          ...state.config,
          countries: action.payload
        }
      };

    case ActionsList.setLanguages:

      return {
        ...state,
        config: {
          ...state.config,
          languages: action.payload
        }
      };

    case ActionsList.setGenders:

      return {
        ...state,
        config: {
          ...state.config,
          genders: action.payload
        }
      };

    default:
      return state;
  }
}
