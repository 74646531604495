<div class="registration-container">
  <h2 class="pb-30px pt-20px">{{ 'HI' | translate }}</h2>
  <div class="option-container">
    <p
      class="pb-45px"
      innerHtml="{{ 'CHECKOUT_BODY_TEXT' | translate }}"
    ></p>
    <div class="d-flex justify-content-center flex-wrap">
      <a
        class="rs-btn--primary"
        href="https://www.rentasolutions.org/"
        mat-button
      >{{ 'BACK_TO_RENTA_SOLUTIONS' | translate }}</a>
    </div>
    <div class="d-flex justify-content-center">
      <a
        class="need-help"
        href="https://rentasolutions.org/en/support-private"
        mat-button
        target="_blank"
      >
        <div class="d-flex justify-content-between align-items-center">
          <mat-icon class="material-icons-outlined">help_outline</mat-icon>
          <p>{{'NEED_HELP' | translate}}</p>
        </div>
      </a>
    </div>
  </div>
</div>