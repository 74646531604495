import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as fromUserReducer from '../reducers/user/user.reducer';
import * as fromAppReducer from '../reducers/app/app.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';
import {
  rsLocalStorageMergeReducer
} from '@rs-shared-lib/3rd-party-libraries/ngrx-store-localstorage/rs-local-storage-merge-reducer';

export interface AppState {
  app: fromAppReducer.State;
  user: fromUserReducer.State;
}

export const reducers: ActionReducerMap<AppState> = {
  app: fromAppReducer.appReducer,
  user: fromUserReducer.userReducer
};

const persistentReducerKeys = [{ app: ['config'] }];

export function localStorageSyncReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return localStorageSync({
    keys: persistentReducerKeys,
    storageKeySerializer: (key): string => 'persistent_' + key,
    rehydrate: true,
    mergeReducer: rsLocalStorageMergeReducer
  })(reducer);
}

export const metaReducers: MetaReducer<AppState>[] = [localStorageSyncReducer];

export function clearPersistentState(): void {
  persistentReducerKeys.forEach((group): void => {
    Object.keys(group).forEach((key) => {
      window.localStorage.removeItem(key);
    })
  });
}
