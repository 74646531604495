<div class="mb-30px position-relative">
  <div
    (click)="handleClick()"
    [class.rs-select-card-disabled]="disabled"
    [class.rs-select-card-hasopt]="extraFields.length"
    [class.rs-select-card-selected]="selected"
    class="rs-select-card card {{customClass}}"
  >
    <div class="d-flex h-100">
      <div class="col-12 col-sm h-100 d-flex flex-column justify-content-between select-card-header">
        <div class="d-flex align-items-center">
          <div class="card-checkbox"></div>
          <h2>{{title | translate}}</h2>
        </div>
        <mat-icon
          [svgIcon]="customIcon"
          class="rs-card-icon"
        >{{!customIcon && icon}}</mat-icon>
      </div>
      <p
        [innerHtml]="description | translate"
        class="col-12 col-sm card-description"
      ></p>
    </div>
  </div>
  <div
    *ngIf="extraFields.length"
    [class.rs-select-card-optional-open]="extraFields.length && selected"
    class="rs-select-card-optional"
  >
    <mat-form-field
      *ngFor="let field of extraFields"
      class="w-100 form-field"
    >
      <mat-label>{{ field.label | translate }}</mat-label>
      <input
        [formControl]="optionalControls[field.name]"
        [type]="field.type"
        autofocus
        matInput
      >
    </mat-form-field>
  </div>
</div>
