<div mat-dialog-content>
  <p>
    <span [innerHTML]="(data.message | translate:{ platform: data.platform })"></span>
    <a
      (click)="filesService.openBase64PDF(conditionDocuments[translateService.currentLang][data.platform.toLowerCase()])"
      class="rs-color--orange"
    >
      {{ 'SPECIFIC_CONDITIONS' | translate:{platform: data.platform} }}.
    </a>
  </p>
</div>

<div mat-dialog-actions>
  <button
    (click)="dialogRef.close()"
    cdkFocusInitial
    class="rs-btn--primary"
    mat-button
  >
    {{'CLOSE' | translate}}
  </button>
</div>